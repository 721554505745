import { 
  CheckOutlined, BarsOutlined, InsertRowAboveOutlined, HomeOutlined, TeamOutlined, TableOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import FormattedMessage from 'components/util-components/IntlMessage'

// Vistas que siempre se mostrarán en el menú lateral
const dashBoardNavTree = [{
  key: 'home',
  path: `${APP_PREFIX_PATH}/home`,
  title: 'leftMenu.home',
  icon: HomeOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'universalUsers',
  path: `${APP_PREFIX_PATH}/universal/users`,
  title: 'leftMenu.users',
  icon: TeamOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'sequenceSelectorPage',
  path: `${APP_PREFIX_PATH}/learning/sequenceSelector`,
  title: 'leftMenu.sequence',
  icon: TableOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'SQMFlujoTrabajo',
  path: `${APP_PREFIX_PATH}/apps/learning/SQM/QuimicaFlujos`,
  title: 'leftMenu.flujoTrabajo',
  icon: BarsOutlined,
  breadcrumb: false,
  submenu: [],
  disabled: true,
},
{
  key: 'SQMPartesPiezas',
  path: `${APP_PREFIX_PATH}/apps/learning/SQM/PartesPiezas`,
  title: 'left.menu.partesPiezas',
  icon: BarsOutlined,
  breadcrumb: false,
  submenu: [],
  disabled: true,
}
]

// Listado de todas las vistas, desde donde se seleccionan las que serán mostradas en menú lateral
const dashBoardNavTreeRaw = [{
  key: 'home',
  path: `${APP_PREFIX_PATH}/home`,
  title: 'leftMenu.home',
  icon: HomeOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'universalUsers',
  path: `${APP_PREFIX_PATH}/universal/users`,
  title: 'leftMenu.users',
  icon: TeamOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'boardsCRUD',
  path: `${APP_PREFIX_PATH}/learning/boards`,
  title: 'leftMenu.boards',
  icon: TableOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'boardsDetailCRUD',
  path: `${APP_PREFIX_PATH}/learning/boardsDetail`,
  title: 'leftMenu.boards',
  icon: TableOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'universalSessions',
  path: `${APP_PREFIX_PATH}/universal/sessions`,
  title: 'leftMenu.sessions',
  icon: TeamOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'sequenceSelectorPage',
  path: `${APP_PREFIX_PATH}/learning/sequenceSelector`,
  title: 'leftMenu.sequence',
  icon: TableOutlined,
  breadcrumb: false,
  submenu: []
},
// ***************************** M O D U L O S **************************************
{
  key: 'EdificationVestimenta',
  path: `${APP_PREFIX_PATH}/apps/learning/Edification/Vestimenta`,
  title: 'leftMenu.vestimenta',
  icon: CheckOutlined,
  breadcrumb: false,
  submenu: [],
  disabled: true,
},
{
  key: 'logisticVestimenta',
  path: `${APP_PREFIX_PATH}/apps/learning/Logistics/Vestimenta`,
  title: 'leftMenu.vestimenta',
  icon: BarsOutlined,
  breadcrumb: false,
  submenu: [],
  disabled: true,
},
{
  key: 'logisticRiesgos',
  path: `${APP_PREFIX_PATH}/apps/learning/Logistics/Riesgos`,
  title: 'leftMenu.riesgos',
  icon: BarsOutlined,
  breadcrumb: false,
  submenu: [],
  disabled: true,
},
{
  key: 'logisticPartesPiezas',
  path: `${APP_PREFIX_PATH}/apps/learning/Logistics/PartesPiezas`,
  title: 'leftMenu.partesypiezas',
  icon: BarsOutlined,
  breadcrumb: false,
  submenu: [],
  disabled: true,
},
{
  key: 'logisticFlujoTrabajo',
  path: `${APP_PREFIX_PATH}/apps/learning/Logistics/FlujoTrabajo`,
  title: 'leftMenu.flujoTrabajo',
  icon: BarsOutlined,
  breadcrumb: false,
  submenu: [],
  disabled: true,
},
{
  key: 'logisticConstruccionLogistica',
  path: `${APP_PREFIX_PATH}/apps/learning/Logistics/ConstruccionLogistica`,
  title: 'left.menu.construccionlogistica',
  icon: InsertRowAboveOutlined,
  breadcrumb: false,
  submenu: [],
  disabled: true
},
{
  key: 'flujoTrabajoCocina',
  path: `${APP_PREFIX_PATH}/apps/learning/Gastronomia/FlujoTrabajo`,
  title: 'left.menu.flujotrabajococina',
  icon: InsertRowAboveOutlined,
  breadcrumb: false,
  submenu: [],
  disabled: true
},
// ************ SNA RIEGO ************
{
  key: 'partesPiezasRiego',
  path: `${APP_PREFIX_PATH}/apps/learning/SNA/Riego/PartesPiezas`,
  title: 'left.menu.partesPiezas',
  icon: CheckOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'montajeRiego',
  path: `${APP_PREFIX_PATH}/apps/learning/SNA/Riego/Montaje`,
  title: 'left.menu.montajeRiego',
  icon: CheckOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'flujoTrabajoRiego',
  path: `${APP_PREFIX_PATH}/apps/learning/SNA/Riego/FlujoTrabajo`,
  title: 'left.menu.flujoTrabajoRiego',
  icon: CheckOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'bodegaRiego',
  path: `${APP_PREFIX_PATH}/apps/learning/SNA/Riego/Bodega`,
  title: 'left.menu.bodegaRiego',
  icon: CheckOutlined,
  breadcrumb: false,
  submenu: []
},
// ************ SNA ALIMENTACION ************
{
  key: 'unityAlimentacionBodega',
  path: `${APP_PREFIX_PATH}/apps/learning/SNA/Alimentacion/Bodega`,
  title: 'left.menu.bodegaAlimentacion',
  icon: CheckOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'unityAlimentacionCocina',
  path: `${APP_PREFIX_PATH}/apps/learning/SNA/Alimentacion/Cocina`,
  title: 'left.menu.cocinaAlimentacion',
  icon: CheckOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'unityAlimentacionComedor',
  path: `${APP_PREFIX_PATH}/apps/learning/SNA/Alimentacion/Comedor`,
  title: 'left.menu.cocinaAlimentacion',
  icon: CheckOutlined,
  breadcrumb: false,
  submenu: []
},
{
  key: 'ModuloTest',
  path: `${APP_PREFIX_PATH}/apps/learning/Test`,
  title: 'ModuloTest',
  icon: CheckOutlined,
  breadcrumb: false,
  submenu: []
},
// ************ SQM QUIMICA *****************
{
  key: 'SQMFlujoTrabajo',
  path: `${APP_PREFIX_PATH}/apps/learning/SQM/QuimicaFlujos`,
  title: 'leftMenu.flujoTrabajo',
  icon: BarsOutlined,
  breadcrumb: false,
  submenu: [],
  disabled: true,
},
{
  key: 'SQMPartesPiezas',
  path: `${APP_PREFIX_PATH}/apps/learning/SQM/PartesPiezas`,
  title: 'left.menu.partesPiezas',
  icon: BarsOutlined,
  breadcrumb: false,
  submenu: [],
  disabled: true,
},
// ************ CONSTRUCCION DEMO ************
{
  key: 'construccionDemo',
  path: `${APP_PREFIX_PATH}/apps/learning/Logistics/ConstruccionDemo`,
  title: 'left.menu.construccionDemo',
  icon: CheckOutlined,
  breadcrumb: false,
  submenu: []
}
]

function setNavigationConfig () {dashBoardNavTreeRaw.map((item) => {
  if(window.localStorage.PRIMARY_DATA !== undefined){
    const leftMenuArray = (JSON.parse(window.localStorage.PRIMARY_DATA).LayoutData.LeftMenu)
    if(leftMenuArray !== undefined){
      if (leftMenuArray.includes(item.key))
        dashBoardNavTree.push(item)
    }
  }
  return true
})
console.log(dashBoardNavTree)
return dashBoardNavTree}

// const navigationConfig = setNavigationConfig()
const navigationConfig = dashBoardNavTree

export default navigationConfig;

// El problema viene de más arriba:
// Tras el login, no se carga navigationConfig, entonces el menú no se actualiza con el nuevo usuario.
// TODO: ver de dónde se invoca este componente para hacer que cargue tras el login.