import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import {
	AUTH_TOKEN,
	SIGNIN,
	SIGNINSECONDARY,
	SIGNOUT,
	SIGNUP,
	FORGET,
	SIGNIN_WITH_GOOGLE,
	SIGNIN_WITH_FACEBOOK
} from '../constants/Auth';
import {
	showAuthMessage,
	authenticated,
	secondaryAuthenticated,
	signOutSuccess,
	signUpSuccess,
	signInWithGoogleAuthenticated,
	signInWithFacebookAuthenticated
} from "../actions/Auth";

import FirebaseService from 'services/FirebaseService'

export function* signInWithFBEmail() {
  yield takeEvery(SIGNIN, function* ({payload}) {
		const {email, password} = payload;
		try {
			const user = yield call(FirebaseService.signInEmailRequest, email, password)
			if (user.message) {
				yield put(showAuthMessage(user.message))
			} else {
				//Acá está logueado. Capturar datos de DB secundaria ¿
				const snap = yield call(FirebaseService.getPrimaryUserData, user.user.uid)
				console.log('user', user)
				const primary = snap.val()
				localStorage.setItem("PRIMARY_DATA", JSON.stringify(primary))
				console.log('PRIMARY DATA', primary)
				console.log('primary rol', primary.Rol)
				console.log('primary rol', primary.Nombre)
				localStorage.setItem("user_role", primary.Rol)
				localStorage.setItem("user_name", primary.Nombre)
				localStorage.setItem(AUTH_TOKEN, user.user.uid)
				localStorage.setItem("auth_email", email)
				// Log into secondary DB
				try {
					// Create secondary Firebase instance
					const rand = Math.random() * 100000
					const secondary = firebase.initializeApp(primary.Firebase, primary.Proyecto + rand);
					localStorage.setItem("SECONDARY_DATA_NAME", primary.Proyecto + rand);
					console.log(secondary)
					yield put(secondaryAuthenticated(secondary));
					secondary					
						.auth()
						.signInAnonymously()						
						.catch(function f(err3) {
							console.log(err3)
							// Handle Errors here.
							// ...
						})					
				} catch (err2) {
					console.log(err2)
					yield put(showAuthMessage(err2));
				}


				yield put(authenticated(user.user.uid));
			}
		} catch (err) {
			yield put(showAuthMessage(err));
		}
	});
}



export function* signInSecondary() {
	yield takeEvery(SIGNINSECONDARY, function* ({payload}) {		
		  const primary = payload;
		  try {
			  // Create secondary Firebase instance
			  const rand = Math.random() * 100000
			  const secondary = firebase.initializeApp(primary.Firebase, primary.Proyecto + rand);
			  localStorage.setItem("SECONDARY_DATA_NAME", primary.Proyecto + rand);
			  yield put(secondaryAuthenticated(secondary));
			  secondary					
				  .auth()
				  .signInAnonymously()				  
				  .catch(function f(err3) {
					  console.log(err3)
					  // Handle Errors here.
					  // ...
				  })
		  } catch (err) {
			  yield put(showAuthMessage(err));
		  }
	  });
  }

  export function* forget() {
	yield takeEvery(FORGET, function* ({payload}) {
		const {email} = payload;
		  try {
			  const forget = yield call(FirebaseService.forgetPassword, email);
			  if (forget === undefined) {
				// Email correcto				
				yield put(showAuthMessage("Hemos enviado a su email las instrucciones para continuar.", "success"));
			} else {
				// Email no válido
				yield put(showAuthMessage("Email no registrado. Por favor, intente de nuevo.", "error"));
			}
		  } catch (err) {
				console.log(err)
			  yield put(showAuthMessage(err));
		  }
	  });
  }




export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
		try {
			const signOutUser = yield call(FirebaseService.signOutRequest);
			if (signOutUser === undefined) {
				localStorage.removeItem(AUTH_TOKEN);
				yield put(signOutSuccess(signOutUser));
			} else {
				yield put(showAuthMessage(signOutUser.message));
			}
		} catch (err) {
			yield put(showAuthMessage(err));
		}
	});
}

export function* signUpWithFBEmail() {
  yield takeEvery(SIGNUP, function* ({payload}) {
		const {email, password} = payload;
		try {
			const user = yield call(FirebaseService.signUpEmailRequest, email, password);
			if (user.message) {
				yield put(showAuthMessage(user.message));
			} else {
				localStorage.setItem(AUTH_TOKEN, user.user.uid);
				yield put(signUpSuccess(user.user.uid));
			}
		} catch (error) {
			yield put(showAuthMessage(error));
		}
	}
	);
}

export function* signInWithFBGoogle() {
  yield takeEvery(SIGNIN_WITH_GOOGLE, function* () {
		try {
			const user = yield call(FirebaseService.signInGoogleRequest);
			if (user.message) {
				yield put(showAuthMessage(user.message));
			} else {
				localStorage.setItem(AUTH_TOKEN, user.user.uid);
				yield put(signInWithGoogleAuthenticated(user.user.uid));
			}
		} catch (error) {
			yield put(showAuthMessage(error));
		}
	});
}

export function* signInWithFacebook() {
  yield takeEvery(SIGNIN_WITH_FACEBOOK, function* () {
		try {
			const user = yield call(FirebaseService.signInFacebookRequest);
			if (user.message) {
				yield put(showAuthMessage(user.message));
			} else {
				localStorage.setItem(AUTH_TOKEN, user.user.uid);
				yield put(signInWithFacebookAuthenticated(user.user.uid));
			}
		} catch (error) {
			yield put(showAuthMessage(error));
		}
	});
}

export default function* rootSaga() {
  yield all([
		fork(signInWithFBEmail),
		fork(signInSecondary),	
		fork(forget),	
		fork(signOut),
		fork(signUpWithFBEmail),
		fork(signInWithFBGoogle),
		fork(signInWithFacebook)
  ]);
}
